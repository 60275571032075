/**
 * https://github.com/firebase/firebase-js-sdk/issues/519#issuecomment-366509360
 * https://stackoverflow.com/questions/39914899/using-firebase-auth-to-access-the-google-calendar-api
 *
 * calendar api example: https://developers.google.com/calendar/api/quickstart/js#set_up_the_sample
 * Kassenbuch Vorlage: https://excelnova.org/wp-content/uploads/2014/04/image27.png
 */
import React, { useEffect, useState } from "react";
import { Alert, Button } from "@mui/material";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { useQueryParam } from "@/customHooks/useQueryParam";
import { CisGoogleAuth } from "@/ModuleCalendarToIncomeStatement/CisGoogleAuth";
import { CisNav } from "@/ModuleCalendarToIncomeStatement/CisNav";
import { CisTables } from "@/ModuleCalendarToIncomeStatement/CisTables";
import { getCalendarsResponse, getEventsResponse } from "@/ModuleCalendarToIncomeStatement/taxDemoData";
import {
    CisCalendarsResponse,
    CisData,
    CisEventItem,
    CisEventsResponse,
    CisMonth
} from "@/ModuleCalendarToIncomeStatement/CisUtils";
import format from "date-fns/format";

export type CisAccessTkn = string | "DEV_ACCESS_TOKEN";

export function CISMainPage() {
    const devMode = useQueryParam("dev-mode");  // if true, use demo data and dont make google api calls

    const [accessTkn, setAccessTkn] = useState<CisAccessTkn>("");
    const [allCalendars, setAllCalendars] = useState<CisCalendarsResponse | null>(null);
    const [calendarData, setCalendarData] = useState<CisEventsResponse | null>(null);
    // TODO: Fill this with the data from calendarData and replace the calendarDataMonths from CisTables.tsx with this
    const [cisData, setCisData] = useState<CisData>({ months: {} });

    const [selectedCalendar, setSelectedCalendar] = useState("");
    const [startDate, setStartDate] = useState<Date>(subDays(new Date(), 30));
    const [endDate, setEndDate] = useState<Date>(new Date());
    const [displayError, setDisplayError] = useState("");

    /** fill cisData */
    const splitEventsByMonth = (): Record<string, CisMonth> => {
        const months: Record<string, CisMonth> = {};
        calendarData?.items
            ?.sort((a: any, b: any) => {
                const dateA = new Date(a.start.dateTime || a.start.date);
                const dateB = new Date(b.start.dateTime || b.start.date);
                return dateA.getTime() - dateB.getTime();
            })
            ?.forEach((item: CisEventItem) => {
                const startDate = new Date(item.start?.dateTime || item.start?.date);
                const monthKey = format(startDate, "yyyy-MM");
                if (!months[monthKey]) {
                    months[monthKey] = { events: [], selectedEventIds: [] };
                }
                months[monthKey].events.push({
                    id: item.id || "",
                    data: item,
                    editedIncome: "test vvv",
                    editedExpense: ""
                });
            });
        return months;
    };

    useEffect(() => {
        setCisData({ months: splitEventsByMonth() });
    }, [calendarData]);

    useEffect(() => {
        console.log("cisData", cisData);
    }, [cisData]);

    /**
     * Get all calendars
     */
    function getAllCalendars() {
        const url = "https://www.googleapis.com/calendar/v3/users/me/calendarList";
        fetch(url, {
            headers: { "Authorization": `Bearer ${accessTkn}` }
        })
            .then(res => res.json())
            .then(data => setAllCalendars(data))
            .catch(err => {
                const msg = "Die Kalender konnten nicht abgerufen werden";
                setDisplayError(msg);
                console.error(msg, err);
            });
    }

    useEffect(() => {
        if (!accessTkn) return;
        if (devMode.value) {
            console.info("DEV MODE: Setting allCalendars to demo data");
            setAllCalendars(getCalendarsResponse);
        } else {
            getAllCalendars();
        }
    }, [accessTkn]);


    /**
     * Get all events from selected calendar
     */
    function getCalendarEvents(calId: string) {
        // const calId = "c7fb666022305dfa421e5026f983950223345c68ae8f422753d901c91e20b3ec@group.calendar.google.com";
        const listUrl = `https://www.googleapis.com/calendar/v3/calendars/${calId}/events`;
        const search = new URLSearchParams({
            maxResults: "10000",
            timeMin: startOfDay(startDate).toISOString(),
            timeMax: endOfDay(endDate).toISOString(),
        });
        fetch(`${listUrl}?${search.toString()}`, {
            headers: { "Authorization": `Bearer ${accessTkn}` }
        })
            .then(res => res.json())
            .then(data => setCalendarData(data))
            .catch(err => {
                const msg = "Die Termine konnten nicht abgerufen werden";
                setDisplayError(msg);
                console.error(msg, err);
            });
    }

    useEffect(() => {
        if (!selectedCalendar) return;
        if (devMode.value) {
            console.info("DEV MODE: Setting calendarData to demo data");
            setCalendarData(getEventsResponse);
        } else {
            getCalendarEvents(selectedCalendar);
        }
    }, [selectedCalendar, startDate, endDate]);


    return <div>
        <div className="flex flex-col gap-4 p-5 items-start">
            {displayError &&
                <Alert color="error" icon={false} onClose={() => setDisplayError("")}>
                    {displayError}
                </Alert>
            }

            {/** Enable/disable local dev mode */}
            {window.location.hostname === "localhost" &&
                <Button
                    size="small"
                    variant="outlined"
                    color={devMode.value ? "warning" : "inherit"}
                    onClick={() => devMode.value ? devMode.remove() : devMode.set("1")}
                >
                    dev mode (mock data): {devMode.value ? "enabled" : "disabled"}
                </Button>
            }

            {!accessTkn ?
                <CisGoogleAuth
                    setAccessTkn={setAccessTkn}
                    isDevMode={!!devMode.value}
                />
                :
                <>
                    <CisNav
                        selectedCalendar={selectedCalendar}
                        setSelectedCalendar={setSelectedCalendar}
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                        allCalendars={allCalendars}
                    />

                    <div className="w-full pb-10 flex flex-col gap-10">
                        {!!selectedCalendar &&
                            <CisTables cisData={cisData} setCisData={setCisData}/>
                        }
                    </div>
                </>
            }
        </div>
    </div>;
}
