import {
    DataGrid,
    GridColDef,
    GridRenderCellParams,
    GridRenderEditCellParams,
    useGridApiContext
} from "@mui/x-data-grid";
import format from "date-fns/format";
import { TextField } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { CisData } from "@/ModuleCalendarToIncomeStatement/CisUtils";
import { cn } from "@/lib/utils";

type FindPriceInStringResponse = null | {
    asString: string;
    isNegative: boolean;
    asNumber: number;
    validDecimalLength: boolean;
    tooManyPrices: boolean;
};

interface Props {
    cisData: CisData;
    setCisData: Dispatch<SetStateAction<CisData>>;
}

export function CisTables(props: Props) {
    function updateSelectedRows(monthKey: string, newSelection: string[]) {
        const tempCisData = structuredClone(props.cisData);
        tempCisData.months[monthKey].selectedEventIds = newSelection;
        props.setCisData(tempCisData);
    }

    function findPriceInString(input: string): FindPriceInStringResponse {
        // const findPrices = input.match(/-?\d+([.,]\d{0,2})?\s*€/g); // this only allows up to 2 decimal places
        const findPrices = input.match(/-?\d+([.,]\d+)?\s*€/g);
        if (!findPrices?.length) return null;

        let price = findPrices[0];

        /** Ensure there is a space between the number and the €*/
        price = price.replace(/(\d)(€)/, "$1 €");

        /** Replace dot with comma*/
        price = price.replace(".", ",");

        /** Check if the price is a whole number and add ",00" if it is */
        if (/^\d+ €$/.test(price)) {
            price = price.replace(" €", ",00 €");
        }

        /** Check if there are more than two decimal places in the price */
        const decimalMatch = price.match(/,(\d+)/);
        const validDecimalLength = !decimalMatch || decimalMatch[1].length <= 2;

        /** Check if there are multiple prices in the string */
        const tooManyPrices = findPrices.length > 1;

        return {
            asString: price,
            isNegative: price.startsWith("-"),
            asNumber: Number(price.replace(",", ".").replace(/[^0-9.-]+/g, "")),
            validDecimalLength,
            tooManyPrices
        };
    }

    function renderEditInputCell(params: GridRenderEditCellParams) {
        console.log("renderEditInputCell CALLED 1", params);
        const apiRef = useGridApiContext();

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            console.log("renderEditInputCell HANDLE CHANGE CALLED", event);
            apiRef.current.setEditCellValue({
                id: params.id,
                field: params.field,
                value: event.target.value,
            });
        };

        return <TextField value={params.value} onChange={handleChange}/>;
    }

    const columns: GridColDef[] = [
        {
            field: "date",
            headerName: "Datum",
            sortable: false,
            editable: false,
            width: 120,
            renderCell: (params: GridRenderCellParams) => {
                const startDate = new Date(params.row.data.start.dateTime || params.row.data.start.date);
                if (!startDate) return "datum invalid";
                return format(startDate, "dd.MM.yyyy");
            }
        },
        {
            field: "customer_name",
            headerName: "Kunde",
            sortable: false,
            editable: true,
            width: 100,
            renderEditCell: (params: GridRenderEditCellParams) => {
                console.log("renderEditCell CALLED", params);
                return <input className="h-full w-full xborder outline-none px-1" autoFocus/>;
            },
            renderCell: (params: GridRenderCellParams) => {
                return "Kunde";
            }
        },
        {
            field: "revenue",
            headerName: "Einnahmen",
            sortable: false,
            editable: true,
            width: 110,
            renderEditCell: (params: GridRenderEditCellParams) => {
                console.log("renderEditCell CALLED", params);
                return <input
                    className="h-full w-full xborder outline-none px-1"
                    value={params.row.editedIncome}
                    onChange={(e) => {
                        console.log("onChange CALLED", e);
                        // TODO: update setCisData here
                        // const tempCisData = structuredClone(props.cisData);
                        // tempCisData.months[monthKey].events[params.id].editedIncome = e.target.value;
                        // props.setCisData(tempCisData);
                    }}
                    autoFocus
                />;
            },
            renderCell: (params: GridRenderCellParams) => {
                const price = findPriceInString(params.row.data.summary);
                if (!price || price.isNegative || !price.validDecimalLength) return "";
                return price.asString;
            }
        },
        {
            field: "expenditure",
            headerName: "Ausgaben",
            sortable: false,
            editable: true,
            width: 110,
            renderCell: (params: GridRenderCellParams) => {
                const price = findPriceInString(params.row.data.summary);
                if (!price || !price.isNegative || !price.validDecimalLength) return "";
                return price.asString.substring(1);
            }
        },
        {
            field: "info",
            headerName: "Anmerkung",
            sortable: false,
            editable: false,
            width: 220,
            renderCell: (params: GridRenderCellParams) => {
                const price = findPriceInString(params.row.data.summary);
                const infos: { text: string, className: string }[] = [];

                if (price) {
                    if (!price.validDecimalLength) infos.push({
                        text: `Ungültiger Preis: ${price.asString}`,
                        className: "text-red-500"
                    });
                    if (price.tooManyPrices) infos.push({
                        text: "Mehrere Preise gefunden",
                        className: "text-orange-500"
                    });
                    if (price.asNumber > 200) infos.push({
                        text: "Ungewöhnlich hohe Einnahme",
                        className: "text-orange-500"
                    });
                    if (price.asNumber < -1_000) infos.push({
                        text: "Ungewöhnlich hohe Ausgabe",
                        className: "text-orange-500"
                    });
                } else {
                    infos.push({
                        text: "Keinen Preis gefunden",
                        className: "text-red-500"
                    });
                }

                return <div className="flex flex-row flex-wrap gap-x-5 gap-y-0">
                    {infos.map((info, index) => (
                        <div
                            className={cn(
                                info.className,
                                infos.length === 2 && "leading-tight",
                                infos.length >= 3 && "!leading-none text-xs"
                            )}
                            key={index}
                        >
                            {info.text}
                        </div>
                    ))}
                </div>;
            }
        },
        {
            field: "startAndEndTime",
            headerName: "Uhrzeit",
            sortable: false,
            editable: true,
            width: 120,
            renderEditCell: renderEditInputCell,
            renderCell: (params: GridRenderCellParams) => {
                const startTime = params.row.data?.start?.dateTime;
                const endTime = params.row.data?.end?.dateTime;
                return <>
                    {startTime && endTime &&
                        <div className="text-sm">
                            {format(new Date(startTime), "HH:mm")} - {format(new Date(endTime), "HH:mm")}
                        </div>
                    }
                </>;
            }
        },
        {
            field: "summary",
            headerName: "Originaltext",
            sortable: false,
            editable: false,
            flex: 1,
            minWidth: 400,
            renderCell: (params: GridRenderCellParams) => {
                return params.row.data?.summary;
            }
        },
    ];

    return <>
        {Object.entries(props.cisData.months).map(([monthKey, monthData], index) => {
            const monthNames = [
                "Januar", "Februar", "März", "April", "Mai", "Juni",
                "Juli", "August", "September", "Oktober", "November", "Dezember"
            ];

            const yearNumber = parseInt(monthKey.split("-")[0]);
            const monthNumber = parseInt(monthKey.split("-")[1]);

            return <div key={index}>
                <div className="pb-2 text-xl font-semibold">
                    {monthNames[monthNumber - 1]} {yearNumber}
                </div>
                <DataGrid
                    rows={monthData.events || []}
                    columns={columns}
                    checkboxSelection
                    autoHeight
                    processRowUpdate={(newRow, oldRow) => {
                        console.log("processRowUpdate", newRow);
                        return newRow;
                    }}
                    onCellEditCommit={(params) => {
                        console.log("onCellEditCommit", params);
                    }}

                    hideFooter
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    density="compact"
                    onSelectionModelChange={(newSelection) => {
                        console.log("onSelectionModelChange", newSelection);
                        updateSelectedRows(monthKey, newSelection.map(v => String(v)));
                    }}
                />
            </div>
        })}
    </>;
}
